// Wrapper around logs to keep a buffer of logs of varying types, used to provide a "log download" button
// that the client can send with error reports.

import { logDumpHistorySize } from '../config';
const MAX_LOG_HISTORY = logDumpHistorySize;

export class LogBuffer {
  constructor() {
    if (!LogBuffer._instance) {
      this._logs = [];
      this._hotkeyTriggered = false;
      LogBuffer._instance = this;
    } else {
      return LogBuffer._instance;
    }
  }

  registerLoggers() {
    this._oldLoggers = {
      log: console.log,
      debug: console.debug,
      warn: console.warn,
      error: console.error,
    };

    console.log = (...params) => {
      this.log('INFO', ...params);
      this._oldLoggers.log(...params);
    };

    console.debug = (...params) => {
      this.log('DEBUG', ...params);
      this._oldLoggers.debug(...params);
    };

    console.warn = (...params) => {
      this.log('WARN', ...params);
      this._oldLoggers.warn(...params);
    };

    console.error = (...params) => {
      this.log('ERROR', ...params);
      this._oldLoggers.error(...params);
    };
  }

  registerKeyboardShortcut() {
    // Registers a key combo of ALT+SHIFT+D (or OPT+SHIFT+D) to download logs.
    console.debug(`[LogBuffer] Registering keyboard shortcut (ALT+SHIFT+D)`);
    window.addEventListener('keydown', (/** @type {KeyboardEvent} **/ ev) => {
      if (ev.shiftKey && ev.altKey && ev.code === 'KeyD') {
        if (!this._hotkeyTriggered) {
          console.debug(`[LogBuffer] Triggering log dump shortcut`);
          this.download();
        }
        this._hotkeyTriggered = true;
      } else {
        this._hotkeyTriggered = false;
      }
    });
  }

  log(type, ...inputs) {
    const date = new Date();
    this._logs.push(`[${type}@${date}] ${inputs.join(' ')}`);
    this._logs = this._logs.slice(-MAX_LOG_HISTORY);
  }

  download() {
    if (this._logs.length === 0) return;
    console.debug(`[LogBuffer] Dumping logs (${this._logs.length} entries)`);
    const buffer = this._logs.join('\n');
    const el = document.createElement('a');
    el.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,` + encodeURIComponent(buffer)
    );
    el.setAttribute('download', `portal-log-${Date.now()}.txt`);
    el.click();
  }
}
